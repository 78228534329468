import { z } from "zod";

export const createImageContentScheme = (imageUrl = "") =>
  z
    .object({
      imageUrl: z.string().default(imageUrl).catch(imageUrl),
    })
    .default({ imageUrl: "" });

export const createButtonContentScheme = (text?: string) =>
  z
    .object({
      heading: z
        .string()
        .default(text ?? "Button")
        .catch(text ?? "Button"),
      link: z.string().default("").catch(""),
    })
    .default({ heading: "Button", link: "" });

export const ButtonContentScheme = createButtonContentScheme();
