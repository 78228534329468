import styled from "styled-components";
import composeCss from "../../composeCss";
import { IBoxBannerSettings } from "./StyledBanner.types";

export const generateBoxBannerStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxBannerSettings["$settings"];
  theme: IBoxBannerSettings["theme"];
}) => {
  const cssStyles = `
    position: relative;
    display: flex;
    height: ${
      $settings.size.includes("px") ? $settings.size : `${$settings.size}px`
    };
    ${$settings.size === "CUSTOM" ? `height: ${$settings.customHeight}px` : ``};
    margin: ${composeCss.margin($settings.margin)};
    border: ${composeCss.border($settings.border, theme)};
  `;

  return cssStyles;
};

export const StyledBanner = styled.div<IBoxBannerSettings>((props) =>
  generateBoxBannerStyles(props)
);
