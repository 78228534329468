import styled from "styled-components";
import {
  IBoxBaseSettings,
  IElementInnerBox,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import { alignmentOptionsToMarginValue, alignmentOptionsToValue } from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";

// ten padding to chyba margin tutaj
const StyledInnerBox = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.renderAs ?? "div",
  show: props?.["$boxSettings"].show,
}))<IBoxBaseSettings<IElementInnerBox>>(
  ({ $boxSettings, $margin, theme }) => `
      display: flex;
      width: ${$boxSettings.width}%;
      height: max-content;
      flex-direction: column;
      gap: 20px;
      padding: ${$boxSettings.textPadding.top}px
      ${$boxSettings.textPadding.right}px
      ${$boxSettings.textPadding.bottom}px 
      ${$boxSettings.textPadding.left}px;
      position: relative;
      z-index: var(--zindex-innerbox);
      background-color: ${convertColorIdToHex(
        $boxSettings.color,
        theme.colorPalette
      )};
      align-items: ${alignmentOptionsToValue($boxSettings.contentAlignment)};
      align-self: ${alignmentOptionsToValue($boxSettings.alignment.vertical)};
      margin-top: ${$margin.top}px;
      margin-bottom: ${$margin.bottom}px;
      margin-left: ${alignmentOptionsToMarginValue("left", $boxSettings.alignment.horizontal, $margin)};
      margin-right: ${alignmentOptionsToMarginValue("right", $boxSettings.alignment.horizontal, $margin)};
      text-align: ${
        alignmentOptionsToValue($boxSettings.contentAlignment) === "flex-end"
          ? "end"
          : alignmentOptionsToValue($boxSettings.contentAlignment)
      };

      .inner-box__button-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: ${$boxSettings.contentAlignment};
      }
`
);

export default StyledInnerBox;
