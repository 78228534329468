import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBorder,
  IBoxBaseSettings,
  IElementButton,
  IElementImage,
  IElementInnerBox,
  IElementTextContent,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ISpacing } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IButtonContent,
  IImageContent,
  ILinkContent,
} from "../../contentPatterns.types";

export enum BannerSizeOptions {
  AUTO = "auto",
  SMALL = "350",
  MEDIUM = "500",
  LARGE = "650",
  CUSTOM = "CUSTOM",
}

export type IBoxBannerData = GenericBoxData<
  IBoxBannerContent,
  IBoxBannerContentSettings,
  IBoxBannerDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export interface IBoxBannerContent {
  title: string;
  description: string;
  bannerUrl: ILinkContent;
  mainButton: IButtonContent;
  secondaryButton: IButtonContent;
  image: IImageContent;
  seoDescription: string;
}

export interface IBoxBannerDisplaySettings {
  size: BannerSizeOptions | string;
  padding: ISpacing;
  margin: ISpacing;
  border: Omit<IBorder, "radius">;
  customHeight: number;
}

export interface IBoxBannerContentSettings {
  image: IElementImage;
  box: IElementInnerBox;
  title: IElementTextContent;
  description: IElementTextContent;
  mainButton: IElementButton;
  secondaryButton: IElementButton;
}

export type IBoxBannerSettings = IBoxBaseSettings<
  IBoxBannerDisplaySettings & IBoxBannerContentSettings
>;
